import React, { useEffect, useState } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faUser, faPhone } from "@fortawesome/free-solid-svg-icons";
import Footer from "../Footer";
import CustomBottomNavigation from "../common/BottomNavigationAction";
import Sidebar from "../Sidebar";
import Skeleton from "react-loading-skeleton";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import TtyOutlinedIcon from '@mui/icons-material/TtyOutlined';
import DialpadRoundedIcon from '@mui/icons-material/DialpadRounded';
import SmsIcon from '@mui/icons-material/Sms';
import {
  FaBeer,
  FaCalendar,
  FaCalendarTimes,
  FaCheckDouble,
  FaCity,
  FaCog,
  FaCoins,
  FaHeart,
  FaImage,
  FaMobile,
  FaPhone,
  FaPhoneAlt,
  FaSearch,
  FaTimes,
  FaTimesCircle,
} from "react-icons/fa";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import "./search.css";
import { Bars } from "react-loader-spinner";
import Select from "react-select";
import { Link } from "react-router-dom";
import WarningIcon from "@mui/icons-material/Warning";
import Swal from "sweetalert2";
import CategoryCarousel from "../CategoryCarousel";
import Alert from "../Alert";
import Categories from "../Categories";

const Annonces = () => {
  const [annonces, setAnnonces] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedService, setSelectedService] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [services, setServices] = useState([]);
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [initialLoading, setInitialLoading] = useState(true);
  const perPage = 16;

  const [selectedCategory, setSelectedCategory] = useState(null);

  const [adData, setAdData] = useState({
    image_url: "",
    publicite_url: "",
    statut: "",
  });
  const [visitCount, setVisitCount] = useState(""); // État pour le nombre de visites
  const [imageUrl, setImageUrl] = useState("");
  const [totalAnnonces, setTotalAnnonces] = useState(0);
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [totalResults, setTotalResults] = useState(0);
  // Nouvel état pour le coût
  const [cost, setCost] = useState("");
  const [budget, setBudget] = useState("");
  const [wishlist, setWishlist] = useState([]);
  const [categories, setCategories] = useState([]);
  const [categoriess, setCategoriess] = useState([]);
  const [communess, setCommuness] = useState([]);
  const [showAlert, setShowAlert] = useState(false);

  const [placeholder, setPlaceholder] = useState("");
  // Fonction pour encoder le message dans l'URL WhatsApp
  const encodeMessage = (message) => {
    return encodeURIComponent(message);
  };


  const trackClick = async (annonceId, userId, channel) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}wp-json/click-tracker/v1/track/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          annonce_id: annonceId,
          user_id: userId, // Vous pouvez récupérer l'ID utilisateur depuis l'authentification
          channel: channel, // Le canal: 'call', 'sms', ou 'whatsapp'
        }),
      });
      const result = await response.json();
      //console.log('Click tracked:', result);
    } catch (error) {
      //console.error('Error tracking click:', error);
    }
  };
  

  // Fonction pour créer le lien WhatsApp
  const createWhatsAppLink = (phone, message) => {
    return `https://wa.me/${phone}?text=${encodeMessage(message)}`;
  };

  const domaine = process.env.REACT_APP_SITE_DOMAINE;

  useEffect(() => {
    const targetText = "De quoi avez-vous besoin ?";
    let index = 0;
    const intervalTime = 300; // Vitesse de l'animation

    const animatePlaceholder = () => {
      setPlaceholder((prev) => {
        if (index >= targetText.length) {
          index = 0;
        }
        const newText = targetText.slice(0, index + 1);
        index++;
        return newText;
      });
    };

    const interval = setInterval(animatePlaceholder, intervalTime);

    return () => clearInterval(interval); // Nettoyage de l'intervalle lors du démontage du composant
  }, []);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const toggleWishlist = (annonce) => {
    const isInWishlist = wishlist.some((item) => item.id === annonce.id);

    if (isInWishlist) {
      Swal.fire({
        title: "Vraiment ?",
        text: "Voulez-vous vraiment retirer cette annonce de votre panier ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui, retirer !",
        cancelButtonText: "Non conserver",
        cancelButtonColor: "#333",
      }).then((result) => {
        if (result.isConfirmed) {
          const updatedWishlist = wishlist.filter(
            (item) => item.id !== annonce.id
          );

          // Sauvegarder la liste de souhaits mise à jour dans le localStorage
          localStorage.setItem("wishlist", JSON.stringify(updatedWishlist));

          // Afficher une alerte pour confirmer que l'élément a été retiré
          Swal.fire(
            "Dommage !",
            "L'annonce a été supprimée de votre panier.",
            "success"
          );

          setWishlist(updatedWishlist);
        }
      });
    } else {
      const updatedWishlist = [...wishlist, annonce];

      // Sauvegarder la liste de souhaits dans le localStorage
      localStorage.setItem("wishlist", JSON.stringify(updatedWishlist));

      // Afficher une alerte pour confirmer que l'élément a été ajouté
      Swal.fire({
        title: "Parfait !",
        text: `l'annonce "${annonce.title}" a été ajoutée à la liste des éléments qui constituent votre budget !`,
        icon: "success",
        confirmButtonText: "OK",
      });

      setWishlist(updatedWishlist);
    }
  };

  // Charger les éléments de la liste de souhaits depuis le localStorage
  useEffect(() => {
    const savedWishlist = localStorage.getItem("wishlist");
    if (savedWishlist) {
      setWishlist(JSON.parse(savedWishlist));
    }
  }, []);

  const formatNumber = (value) => {
    if (!value) return "";
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  };

  const parseNumber = (value) => {
    return value.replace(/\s+/g, "");
  };

  const handleCostChange = (e) => {
    const inputValue = e.target.value;
    const numericValue = parseNumber(inputValue);
    const formattedValue = formatNumber(numericValue);
    setCost(numericValue); // Set the numeric value for internal use
    setBudget(formattedValue); // Update display value if needed
  };

  const handleKeyDown = (e) => {
    const invalidChars = /[a-zA-Z]/;
    const allowedKeys = [
      "Backspace",
      "Delete",
      "ArrowLeft",
      "ArrowRight",
      "Tab",
      "Home",
      "End",
    ];

    if (allowedKeys.includes(e.key)) {
      return;
    }

    if (invalidChars.test(e.key)) {
      e.preventDefault();
    }
  };

  const truncateText = (text, length = 20) => {
    if (text.length <= length) return text;
    return `${text.substring(0, length)}...`;
  };

  useEffect(() => {
    // Remplacez l'URL par celle de votre API si nécessaire
    const url = `${process.env.REACT_APP_API_DOMAIN}/wp-json/v1/afficher_notification_alerte`;
    axios
      .get(url)
      .then((response) => {
        // Assurez-vous que la réponse contient le champ 'afficher_notification_alerte'
        if (response.data && response.data.afficher_notification_alerte) {
          setShowAlert(true);
        } else {
          setShowAlert(false);
        }
      })
      .catch((error) => {
        console.error(
          "Erreur lors de la récupération des notifications :",
          error
        );
        setShowAlert(false);
      });
  }, []); // Le tableau vide signifie que l'effet s'exécute une seule fois après le premier rendu

  useEffect(() => {
    // Fonction pour obtenir l'URL de l'image depuis l'API
    const fetchImageUrl = async () => {
      try {
        const url = `${process.env.REACT_APP_API_DOMAIN}/wp-json/custom/v1/fond-image/`;
        const response = await fetch(url);
        const data = await response.json();

        // Debug: afficher les données obtenues
        //console.log("API Response:", data);

        // Vérifiez si l'URL est retournée correctement
        if (data) {
          setImageUrl(data);
          //console.log("VOILA");
          //console.log(data);
        } else {
          console.error("URL de l'image non trouvée");
        }
      } catch (error) {
        console.error("Erreur lors de la récupération de l'image:", error);
      }
    };

    const fetchTotalAnnonces = async () => {
      try {
        const url = `${process.env.REACT_APP_API_DOMAIN}/wp-json/custom/v1/total-annonces/`;
        const response = await axios.get(url);
        setTotalAnnonces(response.data.total_annonces);
      } catch (error) {
        console.error("Erreur lors de la récupération des annonces:", error);
      }
    };

    fetchTotalAnnonces();
    fetchImageUrl();
  }, []);

  const fetchAdData = async () => {
    try {
      const url = `${process.env.REACT_APP_API_DOMAIN}/wp-json/myplugin/v1/derniere-publicite`;
      const response = await fetch(url);
      const data = await response.json();

      return {
        image_url: data.image_url || "",
        publicite_url: data.publicite_url || "",
        statut: data.statut || "", // Inclure le statut
      };
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des données de la publicité:",
        error
      );
      return { image_url: "", publicite_url: "", statut: "" }; // Inclure un statut par défaut
    }
  };

  useEffect(() => {
    const getAdData = async () => {
      const data = await fetchAdData();
      setAdData(data);
    };

    getAdData();
  }, []);

  useEffect(() => {
    fetchAnnonces(currentPage); // Charger les annonces à chaque changement de page
  }, [currentPage]);

  const fetchAnnonces = async (page = 1, category = null, commune = null) => {
    try {
      setLoading(true);

      //console.log("Selected Service:", selectedService);
      //console.log("Selected Location:", selectedLocation);
      //console.log("Cost:", cost);

      // Construisez les paramètres de la requête
      const serviceQuery =
        selectedService && selectedService.value
          ? `&categorie_annonce=${selectedService.value}`
          : "";
      const locationQuery =
        selectedLocation && selectedLocation.value
          ? `&commune=${selectedLocation.value}`
          : "";
      const costQuery = cost ? `&cout=${encodeURIComponent(cost)}` : "";

      // Nouveau paramètre pour la catégorie
      const categoryQuery = category
        ? `&categorie_annonce=${encodeURIComponent(category)}`
        : "";

      // Nouveau paramètre pour la commune
      const communeQuery = commune
        ? `&commune=${encodeURIComponent(commune)}`
        : "";

      // Construire l'URL avec les paramètres de recherche
      const url = `${process.env.REACT_APP_API_ROOT_CUSTOM_V1}/annonces?page=${page}&per_page=${perPage}${serviceQuery}${locationQuery}${costQuery}${categoryQuery}${communeQuery}`;

      //console.log("Fetching URL:", url);

      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Erreur lors de la récupération des annonces");
      }
      const data = await response.json();

      //console.log("Response Data:", data);

      setAnnonces(data.data || []);
      setTotalPages(data.pagination ? data.pagination.total_pages : 1);
      setTotalResults(data.pagination ? data.pagination.total_items : 0);
    } catch (error) {
      console.error("Error fetching annonces:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAnnonces(); // Charger les annonces au premier rendu
  }, []);

  useEffect(() => {
    fetchServices();
    fetchLocations();
  }, []);

  const fetchServices = async () => {
    try {
      const url = `${process.env.REACT_APP_API_ROOT_CUSTOM_V1}/categories-annonce`;
      const response = await axios.get(url);
      setServices(
        response.data.map((service) => ({
          value: service.name,
          label: service.name,
        }))
      );
      setInitialLoading(false);
    } catch (error) {
      console.error("Error fetching services:", error);
      setInitialLoading(false);
    }
  };

  useEffect(() => {
    const url = `${process.env.REACT_APP_API_DOMAIN}/wp-json/custom/v1/categories/`;
    axios
      .get(url)
      .then((response) => {
        setCategoriess(response.data);
      })
      .catch((error) => {
        console.error("Erreur lors de la récupération des catégories:", error);
      });
  }, []);

  useEffect(() => {
    const url = `${process.env.REACT_APP_API_DOMAIN}/wp-json/custom/v1/bycommunes/`;
    axios
      .get(url)
      .then((response) => {
        setCommuness(response.data);
      })
      .catch((error) => {
        console.error("Erreur lors de la récupération des communes:", error);
      });
  }, []);

  const fetchLocations = async () => {
    try {
      const url = `${process.env.REACT_APP_API_ROOT_CUSTOM_V1}/communes`;
      const response = await axios.get(url);
      setLocations(
        response.data.map((location) => ({
          value: location.name,
          label: location.name,
        }))
      );
    } catch (error) {
      console.error("Error fetching locations:", error);
    }
  };

  const handleSearch = (event) => {
    event.preventDefault();
    setCurrentPage(1); // Reset to first page on new search
    fetchAnnonces(1); // Fetch annonces for the first page with new filters
  };

  useEffect(() => {
    // Fonction pour enregistrer la visite
    const trackVisit = async () => {
      try {
        const url = `${process.env.REACT_APP_API_DOMAIN}/wp-json/custom/v1/track-visit/`;
        await fetch(url, {
          method: "POST",
        });
      } catch (error) {
        console.error("Erreur lors du comptage de la visite:", error);
      }
    };

    const fetchVisitCount = async () => {
      try {
        const url2 = `${process.env.REACT_APP_API_DOMAIN}/wp-json/custom/v1/visit-count`;
        const response = await fetch(url2);
        const data = await response.json();
        setVisitCount(data.visit_count);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération du nombre de visites:",
          error
        );
      }
    };

    //trackVisit();
    //fetchVisitCount();

    const fetchData = async () => {
      await trackVisit();
      await fetchVisitCount();
      setInitialLoading(false); // Arrêter le chargement initial
    };

    fetchData();
  }, []);

  useEffect(() => {
    // Remplacez l'URL ci-dessous par l'URL de votre API
    const url = `${process.env.REACT_APP_API_DOMAIN}/wp-json/custom/v1/categories/`;
    axios
      .get(url)
      .then((response) => {
        setCategories(response.data);
      })
      .catch((error) => {
        console.error("Erreur lors de la récupération des catégories:", error);
      });
  }, []);

  useEffect(() => {
    fetchCategories(); // Récupère les catégories à l'initialisation du composant
  }, []);

  const fetchCategories = async () => {
    try {
      const url = `${process.env.REACT_APP_API_ROOT_CUSTOM_V1}/categories-annonce`;
      const response = await axios.get(url);
      setCategories(response.data);
    } catch (error) {
      console.error("Erreur lors de la récupération des catégories:", error);
    }
  };

  const fetchAnnoncesByCategory = async (categoryId) => {
    setLoading(true);
    try {
      const url = `${process.env.REACT_APP_API_ROOT_CUSTOM_V1}/annonces?categorie_id=${categoryId}`;
      const response = await axios.get(url);
      setAnnonces(response.data.data || []);
    } catch (error) {
      console.error("Erreur lors de la récupération des annonces:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCategoryClick = (category) => {
    fetchAnnonces(1, category, null); // Appel de la fonction fetchAnnonces avec la catégorie sélectionnée
    setSelectedLocation("");
    setSelectedService("");
  };

  const handleCommuneClick = (commune) => {
    //console.log(commune)
    fetchAnnonces(1, null, commune); // Appel de la fonction fetchAnnonces avec la catégorie sélectionnée
    setSelectedLocation("");
    setSelectedService("");
  };

  // Affiche les 4 premières catégories si showAllCategories est faux, sinon affiche toutes les catégories

  const [showAllCategories, setShowAllCategories] = useState(false);
  // Filtrer les catégories à afficher en fonction de l'état showAllCategories
  const displayedCategories = showAllCategories
    ? categoriess
    : categoriess.slice(0, 4);

  const handleToggleCategories = () => {
    setShowAllCategories(!showAllCategories);
  };

  const [showAllCommunes, setShowAllCommunes] = useState(false);
  // Filtrer les catégories à afficher en fonction de l'état showAllCategories
  const displayedCommunes = showAllCommunes ? communess : communess.slice(0, 4);

  const handleToggleCommunes = () => {
    setShowAllCommunes(!showAllCommunes);
  };

  return (
    <>
      {showAlert && <Alert />}

      {loading ? (
        <div className="loading-overlay">
          <Bars
            height="100"
            width="100"
            color="#e91e63"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass="bars-wrapper"
          />
        </div>
      ) : initialLoading ? (
        <div className="loading-overlay">
          <Bars
            height="100"
            width="100"
            color="#e91e63"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass="bars-wrapper"
          />
        </div>
      ) : (
        <>
          <div
            className="container"
            style={{
              marginTop: -5,
              overflowY: "auto",
              minHeight: "100vh",
              background: "white",
            }}
          >
            <Sidebar />

            {/* Première ligne */}
            <div
              className="flex justify-center items-center py-4"
              style={{ background: "white" }}
            >
              {/* Zone avec image de fond et barre de recherche */}
              <div
                className="relative w-full custom-height bg-cover bg-center flex justify-center items-center"
                style={{
                  backgroundImage: `url(${
                    imageUrl ||
                    "https://cdn.pixabay.com/photo/2016/11/21/15/58/wedding-1846114_1280.jpg"
                  })`,
                }}
              >
                <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 flex flex-col justify-center items-center px-4">
                  <div
                    className="text-pink-500 text-lg md:text-2xl mb-4 mt-2 text-center"
                    style={{
                      fontSize: 30,
                      fontWeight: "bolder",
                      color: "white",
                      marginBottom: 30,
                      marginTop: 30,
                    }}
                  >
                    {/* <p style={{lineHeight:1}}>Tout ce dont vous avez besoin pour votre mariage</p> */}
                    <p
                      className="text-[25px] md:text-[30px] lg:text-[50px] leading-tight"
                      style={{ fontFamily: "Pacifico" }}
                    >
                      Tout ce dont vous avez besoin pour votre mariage !
                    </p>
                    {/* <p style={{fontSize:12, marginTop:10}} className="leading-tight">       
                naviguez dans {totalAnnonces.toLocaleString()} {totalAnnonces.toLocaleString() >1 ? "annonces":"annonce"} publiées au total
                </p> */}
                  </div>
                  <div
                    className="rounded-lg p-4 shadow-lg w-full max-w-5xl text-center"
                    style={{ marginTop: -12 }}
                    style={{ background: "rgba(255, 255, 255, 0.5)" }}
                  >
                    <form
                      className="flex flex-col md:flex-row justify-center items-center space-y-4 md:space-y-0 md:space-x-4"
                      onSubmit={handleSearch}
                    >
                      <Select
                        options={services}
                        value={selectedService}
                        onChange={setSelectedService}
                        placeholder={placeholder}
                        isClearable={true}
                        className="w-full"
                      />
                      <Select
                        options={locations}
                        value={selectedLocation}
                        onChange={setSelectedLocation}
                        placeholder="où ?"
                        isClearable={true}
                        className="w-full"
                      />

                      {/* Champ de saisie pour le coût */}
                      <input
                        type="text"
                        placeholder="Votre Budget Maximum"
                        value={budget}
                        onChange={handleCostChange}
                        onKeyDown={handleKeyDown}
                        inputMode="numeric"
                        className="form-control w-full"
                        style={{
                          width: "100%",
                          border: "1px solid #ccc",
                          borderRadius: "5px",
                          padding: 10,
                        }}
                        autoComplete="false"
                      />

                      <button
                        type="submit"
                        className="w-full md:w-auto px-4 py-2 rounded-md bg-[#E91E63] text-white flex items-center justify-center space-x-2 font-bold"
                        style={{ padding: 10 }}
                      >
                        <FaSearch />
                        <span>Chercher</span>
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <>
              <div
                className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4 lg:pl-10 lg:pr-10"
                style={{ background: "white" }}
              >
                <p
                  style={{
                    marginLeft: 20,
                    fontWeight: "bolder",
                    marginTop: 5,
                    marginBottom: 5,
                  }}
                  className="text-[17px] md:text-[20px] lg:text-[19px]"
                >
                  <LocalOfferIcon style={{ color: "#e91e63" }} /> Chercher par
                  Catégorie
                </p>
              </div>
              <div
                className="grid grid-cols-2 gap-4 md:grid-cols-4 lg:grid-cols-4 lg:pl-10 lg:pr-10"
                style={{
                  background: "white",
                  marginBottom: "90px",
                  margin: 10,
                  cursor: "pointer",
                }}
              >
                {displayedCategories.map((category) => (
                  <div
                    key={category.id}
                    className="relative flex flex-col items-center p-4 rounded-lg shadow-md cursor-pointer transition-transform transform hover:scale-105"
                    onClick={() => handleCategoryClick(category.name)}
                    style={{
                      boxShadow: "0px 1px 10px -2px #828282",
                      backgroundImage: `url(${category.icon})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      color: "white",
                      height: "150px", // Augmente la hauteur de chaque carte
                    }}
                  >
                    {/* Overlay sombre */}
                    <div className="absolute inset-0 bg-black opacity-50 rounded-lg"></div>
                    <div className="relative z-10 flex flex-col items-center justify-center h-full">
                      <p className="text-lg font-semibold text-white-500 mb-2">
                        {category.name}
                      </p>
                      <p className="text-sm text-white-500">
                        ({category.count})
                      </p>
                    </div>
                  </div>
                ))}
              </div>

              {/* Bouton Toggle pour afficher/masquer les catégories */}
              {categoriess.length > 4 && (
                <div className="text-center mt-4">
                  <button
                    onClick={handleToggleCategories}
                    className="bg-pink-500 text-white py-2 px-4 rounded hover:bg-pink-700 transition duration-300"
                    style={{ backgroundColor: "rgb(233 30 100)", marginTop: 5 }}
                  >
                    {showAllCategories ? "Masquer" : "Voir plus"}{" "}
                    <MoreHorizIcon />
                  </button>
                </div>
              )}
            </>

            {/* explorer par commune */}

            <>
              <div
                className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4 lg:pl-10 lg:pr-10"
                style={{ background: "white" }}
              >
                <p
                  style={{
                    marginLeft: 20,
                    fontWeight: "bolder",
                    marginTop: 5,
                    marginBottom: 5,
                  }}
                  className="text-[17px] md:text-[20px] lg:text-[19px]"
                >
                  <LocalOfferIcon style={{ color: "#e91e63" }} /> Chercher par
                  Commune
                </p>
              </div>
              <div
                className="grid grid-cols-2 gap-4 md:grid-cols-4 lg:grid-cols-4 lg:pl-10 lg:pr-10"
                style={{
                  background: "white",
                  marginBottom: "90px",
                  margin: 10,
                  cursor: "pointer",
                }}
              >
                {displayedCommunes.map((commune) => (
                  <div
                    key={commune.id}
                    className="relative flex flex-col items-center p-4 rounded-lg shadow-md cursor-pointer transition-transform transform hover:scale-105"
                    onClick={() => handleCommuneClick(commune.name)}
                    style={{
                      boxShadow: "0px 1px 10px -2px #828282",
                      backgroundImage: `url(${commune.icon})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      color: "white",
                      height: "150px", // Augmente la hauteur de chaque carte
                    }}
                  >
                    {/* Overlay sombre */}
                    <div className="absolute inset-0 bg-black opacity-50 rounded-lg"></div>
                    <div className="relative z-10 flex flex-col items-center justify-center h-full">
                      <p className="text-lg font-semibold text-white-500 mb-2">
                        {commune.name}
                      </p>
                      <p className="text-sm text-white-500">
                        ({commune.count})
                      </p>
                    </div>
                  </div>
                ))}
              </div>

              {/* Bouton Toggle pour afficher/masquer les communes */}
              {communess.length > 4 && (
                <div className="text-center mt-4">
                  <button
                    onClick={handleToggleCommunes}
                    className="bg-pink-500 text-white py-2 px-4 rounded hover:bg-pink-700 transition duration-300"
                    style={{ backgroundColor: "rgb(233 30 100)", marginTop: 5 }}
                  >
                    {showAllCommunes ? "Masquer" : "Voir plus"}{" "}
                    <MoreHorizIcon />
                  </button>
                </div>
              )}
            </>

            <div
              className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4 lg:pl-10 lg:pr-10"
              style={{ background: "white" }}
            >
              <p
                style={{
                  marginLeft: 20,
                  fontWeight: "bolder",
                  marginTop: 5,
                  marginBottom: 5,
                }}
                className="text-[17px] md:text-[20px] lg:text-[19px]"
              >
                {" "}
                <LocalOfferIcon style={{ color: "#e91e63" }} /> {totalResults}{" "}
                {totalResults > 1 ? "annonces trouvés" : "annonce trouvé"}{" "}
              </p>
            </div>

            {adData.statut.toLowerCase() === "active" && (
              <div
                className="grid lg:pl-10 lg:pr-10"
                style={{ background: "white" }}
              >
                <center>
                  <a
                    href={adData.publicite_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={adData.image_url}
                      alt="Logo publicitaire"
                      style={{
                        width: "90%",
                        marginTop: "2%",
                        marginBottom: "4%",
                      }}
                    />
                  </a>
                </center>
              </div>
            )}

            {annonces.length === 0 ? (
              <div
                className="grid gap-4  lg:pl-10 lg:pr-10"
                style={{ background: "white" }}
              >
                <p
                  style={{
                    marginLeft: 20,
                    fontWeight: "bolder",
                    fontSize: 18,
                    marginTop: 20,
                    marginBottom: 20,
                    color: "#E91E63",
                  }}
                >
                  {" "}
                  <WarningIcon /> Aucun résultat trouvé pour cette recherche
                </p>
              </div>
            ) : (
              <>
                <div
                  className="grid grid-cols-1 gap-0 md:grid-cols-2 lg:grid-cols-4 lg:pl-10 lg:pr-10"
                  style={{ background: "white" }}
                >
                  {annonces.map((annonce) => (
                    <div
                      key={annonce.id}
                      className="card shadow-lg rounded-lg flex flex-col gap-5 p-0 bg-white m-4"
                      style={{
                        //boxShadow: "0 2px 8px #00000026",
                        boxShadow: "0px 1px 10px -2px #828282",
                        borderRadius: ".5rem",
                      }}
                    >
                      <Link to={`/details/${annonce.slug}`}>
                        <div className="w-full h-64 sm:h-80 md:h-96 lg:h-[200px] overflow-hidden flex justify-center items-center bg-gray-100 relative">
                          <img
                            src={
                              annonce.featured_image ||
                              "https://via.placeholder.com/150"
                            }
                            alt={annonce.title}
                            className="w-full h-full object-cover rounded-lg "
                          />

                          {/* Étiquette de la catégorie */}
                          <div
                            className="absolute top-2 right-2 bg-pink-500 text-white py-1 px-2 rounded-lg text-sm font-semibold"
                            style={{ zIndex: 10 }}
                          >
                            {annonce.categories}
                          </div>
                        </div>
                      </Link>
                      <h2
                        className="text-lg font-medium pl-5 pr-5 pb-5"
                        style={{ fontWeight: 700, fontSize: "17px" }}
                      >
                        <Link to={`/details/${annonce.slug}`}>
                          {/*truncateText(annonce.title, 10)*/}
                          {annonce.title}
                        </Link>

                        <div
                          className="flex items-center"
                          style={{
                            fontWeight: 400,
                            color: "#6c6c6c",
                            fontSize: 15,
                          }}
                        >
                          <FaCoins className="mr-2" />
                          {annonce.acf_fields.cout
                            ? `${parseInt(
                                annonce.acf_fields.cout
                              ).toLocaleString()} F CFA`
                            : "Non spécifié"}
                          <button
                            className={`wishlist-button ${
                              wishlist.some((item) => item.id === annonce.id)
                                ? "active"
                                : ""
                            }`}
                            onClick={(e) => {
                              e.stopPropagation(); // Empêche le clic de passer au Link
                              toggleWishlist(annonce);
                            }}
                            style={{
                              margin: "0 1rem",
                              background: "none",
                              border: "none",
                            }}
                          >
                            <FaHeart
                              style={{
                                color: wishlist.some(
                                  (item) => item.id === annonce.id
                                )
                                  ? "#E91E63"
                                  : "grey",
                                fontSize: "1.5rem",
                              }}
                              className="step-1"
                            />
                          </button>
                          <TtyOutlinedIcon className="mr-2" style={{color:'#128c7e', fontSize:25, cursor:'pointer'}}  
                          onClick={() => {                            
                            trackClick(annonce.id, annonce.author_id, 'call'); // Enregistrement du clic
                            console.log('Click tracked for call'); // Affiche un message sans exécuter à nouveau la fonction
                                window.open(
                                  `tel:${annonce.acf_fields.telephone}`,
                                  "_blank"
                                );
                            }}/>

<SmsIcon
  className="ml-2"
  style={{ color: "", fontSize: 25, cursor: "pointer" }}
  onClick={(e) => {
    e.preventDefault(); // Empêche le comportement par défaut
    trackClick(annonce.id, annonce.author_id, 'sms'); // Enregistrement du clic

    Swal.fire({
      title: "Attention",
      text: "N’effectuez aucun paiement avant d’avoir l’assurance que c’est le bon prestataire !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Envoyer SMS",
      reverseButtons: true,  // Inverse l'ordre des boutons
      cancelButtonText: "Annuler",
      customClass: {
        cancelButton: "swal2-cancel-button",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        // Si l'utilisateur confirme, le SMS est envoyé
        const message = `Bonjour Madame/Monsieur ${
          annonce.author_name
        }, j'ai trouvé votre service sur WEDDAPP.CI, lien : ${`${process.env.REACT_APP_SITE_DOMAINE}details/${annonce.slug}`}, je suis intéressé.`;

        window.open(`sms:${annonce.acf_fields.telephone}?body=${encodeURIComponent(message)}`, "_blank");
      }
    });
  }}
/>
                        </div>

                        <div
                          className="flex items-center"
                          style={{
                            fontWeight: 800,
                            color: "#E91E63",
                            fontSize: 15,
                          }}
                        >
                          <Link to={`/boutique/${annonce.author_name}`}>
                            <PersonOutlineOutlinedIcon className="mr-2" />
                            par {annonce.author_name || "Inconnu"}
                          </Link>

                          
                          {/*  <span className="ml-4 items-center flex" style={{fontSize:10, color:'green'}}><FaCheckDouble/> identité vérifiée</span> */}
                        </div>
                        <div
                          className="flex items-center"
                          style={{
                            fontWeight: 800,
                            color: "#128c7e",
                            fontSize: 15,
                          }}
                        >
                          <FaCity className="mr-2" />
                          service à {annonce.communes || "Inconnu"}
                          {/*  <span className="ml-4 items-center flex" style={{fontSize:10, color:'green'}}><FaCheckDouble/> identité vérifiée</span> */}
                        </div>

                        

                        <div
                          className="flex justify-center items-center"
                          style={{
                            fontWeight: 800,
                            color: "white",
                            fontSize: 15,
                            background: "#075E54",
                            padding: "4px",
                            borderRadius: 4,
                            marginTop: 5,
                            cursor:'pointer'
                          }}

                          onClick={(e) => {
                              e.preventDefault(); // Empêche la redirection automatique
                              trackClick(annonce.id, annonce.author_id, 'whatsapp'); // Enregistrement du clic
                              Swal.fire({
                                title: "Attention",
                                text: "N’effectuez aucun paiement avant d’avoir l’assurance que c’est le bon prestataire !",
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonText: "Continuer",
                                reverseButtons: true,  // Inverse l'ordre des boutons
                                cancelButtonText: "Annuler",
                                customClass: {
                                  cancelButton: "swal2-cancel-button",
                                },
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  // Redirige vers WhatsApp si l'utilisateur confirme
                                  window.open(
                                    createWhatsAppLink(
                                      annonce.acf_fields.telephone,
                                      `Bonjour Madame/Monsieur ${
                                        annonce.author_name
                                      }, j'ai trouvé votre service sur WEDDAPP.CI, lien : ${`${process.env.REACT_APP_SITE_DOMAINE}details/${annonce.slug}`}, je suis intéressé.`
                                    ),
                                    "_blank",
                                    "noopener,noreferrer"
                                  );
                                }
                              });
                            }}
                        >
                          {annonce.acf_fields.cout ? (
                            <div
                            >
                              <FontAwesomeIcon icon={faWhatsapp} /> écrire sur whatsapp
                              
                            </div>
                          ) : (
                            "Non spécifié"
                          )}
                        </div>
                      </h2>
                    </div>
                  ))}
                </div>

                <div
                  className="flex flex-col space-y-4 pt-8"
                  style={{ background: "white" }}
                >
                  <div className="text-center" style={{ background: "white" }}>
                    <div className="" style={{ marginBottom: "30px" }}>
                      <button
                        className="btn-primary text-white bg-pink-500 rounded-lg hover:shadow-lg disabled:opacity-50"
                        disabled={currentPage === 1}
                        onClick={() => setCurrentPage(currentPage - 1)}
                        style={{
                          marginRight: "5px",
                          padding: 8,
                          fontSize: 16,
                          fontWeight: "bolder",
                        }}
                      >
                        Précédent
                      </button>
                      <span
                        style={{
                          margin: "0 5px",
                          fontSize: 15,
                          fontWeight: "bolder",
                        }}
                      >
                        Page {currentPage} sur {totalPages}
                      </span>
                      <button
                        className="btn-primary text-white bg-pink-500 rounded-lg hover:shadow-lg disabled:opacity-50"
                        onClick={() => setCurrentPage(currentPage + 1)}
                        disabled={currentPage === totalPages}
                        style={{
                          marginLeft: "5px",
                          padding: 8,
                          fontSize: 16,
                          fontWeight: "bolder",
                        }}
                      >
                        Suivant
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}

            <div
              className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4 lg:pl-32 lg:pr-32"
              style={{ background: "white" }}
            >
              <br />
              <br />
              <br />
              <br />
            </div>
          </div>
        </>
      )}
      <CustomBottomNavigation />
    </>
  );
};

export default Annonces;
