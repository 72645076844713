import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import UndoSharpIcon from '@mui/icons-material/UndoSharp';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PersonIcon from '@mui/icons-material/Person';
import { FaCity, FaCoins, FaHeart, FaWhatsapp } from 'react-icons/fa';
import CustomBottomNavigation from '../../common/BottomNavigationAction';
import StorefrontIcon from '@mui/icons-material/Storefront';
import Swal from 'sweetalert2';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import Sidebar from '../../Sidebar';
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import SmsIcon from '@mui/icons-material/Sms';
import TtyOutlinedIcon from "@mui/icons-material/TtyOutlined";

const AnnoncesByUser = () => {
  const { username } = useParams();
  const [annonces, setAnnonces] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [user, setUser] = useState(null); // Ajouter état pour les informations utilisateur
  const [wishlist, setWishlist] = useState([]);
  const [post, setPost] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false); // Ajouter l'état pour la modale
  const [adData, setAdData] = useState({
    image_url: "",
    publicite_url: "",
    statut: "",
  });
  // Fonction pour encoder le message dans l'URL WhatsApp
  const encodeMessage = (message) => {
    return encodeURIComponent(message);
  };

  // Fonction pour créer le lien WhatsApp
  const createWhatsAppLink = (phone, message) => {
    return `https://wa.me/${phone}?text=${encodeMessage(message)}`;
  };


  useEffect(() => {
    const getAdData = async () => {
      const data = await fetchAdData();
      setAdData(data);
    };

    getAdData();
  }, []);



  const trackClick = async (annonceId, userId, channel) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}wp-json/click-tracker/v1/track/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          annonce_id: annonceId,
          user_id: userId, // Vous pouvez récupérer l'ID utilisateur depuis l'authentification
          channel: channel, // Le canal: 'call', 'sms', ou 'whatsapp'
        }),
      });
      const result = await response.json();
      //console.log('Click tracked:', result);
    } catch (error) {
      //console.error('Error tracking click:', error);
    }
  };

  const fetchAdData = async () => {
    try {
      const url = `${process.env.REACT_APP_API_DOMAIN}/wp-json/myplugin/v1/derniere-publicite`;
      const response = await fetch(url);
      const data = await response.json();

      return {
        image_url: data.image_url || "",
        publicite_url: data.publicite_url || "",
        statut: data.statut || "", // Inclure le statut
      };
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des données de la publicité:",
        error
      );
      return { image_url: "", publicite_url: "", statut: "" }; // Inclure un statut par défaut
    }
  };

  useEffect(() => {
    const getAdData = async () => {
      const data = await fetchAdData();
      setAdData(data);
    };

    getAdData();
  }, []);



  useEffect(() => {
    const fetchAnnonces = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_DOMAIN}wp-json/custom/v1/annonce/owner/${username}`);
        setAnnonces(response.data.data);
        setUser(response.data.user); // Sauvegarder les informations utilisateur
      } catch (err) {
        setError('Erreur de chargement des annonces');
      } finally {
        setLoading(false);
      }
    };

    fetchAnnonces();
  }, [username]);

  const handleClick = () => {
    //navigate("/");
    navigate(-1);
  };



  const toggleWishlist = (annonce) => {
    const isInWishlist = wishlist.some((item) => item.id === annonce.id);

    if (isInWishlist) {
      Swal.fire({
        title: "Vraiment ?",
        text: "Voulez-vous vraiment retirer cette annonce de votre panier ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui, retirer !",
        cancelButtonText: "Non conserver",
        cancelButtonColor: "#333",
      }).then((result) => {
        if (result.isConfirmed) {
          const updatedWishlist = wishlist.filter(
            (item) => item.id !== annonce.id
          );

          // Sauvegarder la liste de souhaits mise à jour dans le localStorage
          localStorage.setItem("wishlist", JSON.stringify(updatedWishlist));

          // Afficher une alerte pour confirmer que l'élément a été retiré
          Swal.fire(
            "Dommage !",
            "L'annonce a été supprimée de votre panier.",
            "success"
          );

          setWishlist(updatedWishlist);
        }
      });
    } else {
      const updatedWishlist = [...wishlist, annonce];

      // Sauvegarder la liste de souhaits dans le localStorage
      localStorage.setItem("wishlist", JSON.stringify(updatedWishlist));

      // Afficher une alerte pour confirmer que l'élément a été ajouté
      Swal.fire({
        title: "Parfait !",
        text: `l'annonce "${annonce.title}" a été ajoutée à la liste des éléments qui constituent votre budget !`,
        icon: "success",
        confirmButtonText: "OK",
      });

      setWishlist(updatedWishlist);
    }
  };

  // Charger les éléments de la liste de souhaits depuis le localStorage
  useEffect(() => {
    const savedWishlist = localStorage.getItem("wishlist");
    if (savedWishlist) {
      setWishlist(JSON.parse(savedWishlist));
    }
  }, []);


  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);
    const nom = formData.get('nom');
    const prenom = formData.get('prenom');
    const contact = formData.get('contact');
    const message = formData.get('message');

    if (!nom || !prenom || !contact || !message) {
      await Swal.fire({
        title: 'Champs requis',
        text: 'Veuillez remplir tous les champs du formulaire avant de soumettre.',
        icon: 'error',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'OK',
      });
      return;
    }

    if (!user) {
      await Swal.fire({
        title: 'Erreur',
        text: 'Les informations de l\'utilisateur ne sont pas disponibles.',
        icon: 'error',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'OK',
      });
      return;
    }

    const { phone_number } = user; // Utiliser le numéro de téléphone de l'utilisateur
    const { value: confirm } = await Swal.fire({
      title: 'Confirmer l\'envoi',
      text: `Voulez-vous envoyer ce message au numéro WhatsApp de l'annonceur (${phone_number}) ?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, envoyer !',
      cancelButtonText: 'Annuler',
    });

    if (confirm) {
      const formattedMessage = `Nom: ${nom}\nPrénom: ${prenom}\nContact: ${contact}\nMessage: ${message}\n\nEnvoyé depuis www.weddapp.ci`;
      
      // Créez l'URL WhatsApp avec le message
      const whatsappUrl = `https://api.whatsapp.com/send?phone=${encodeURIComponent(phone_number)}&text=${encodeURIComponent(formattedMessage)}`;

      // Ouvrir l'URL dans un nouvel onglet
      window.open(whatsappUrl, '_blank');
      
      // Afficher un message de confirmation
      Swal.fire(
        'Envoyé !',
        'Votre message a été envoyé avec succès.',
        'success'
      );
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  if (loading) return <div className="text-center p-4">Chargement...</div>;
  if (error) return <div className="text-center p-4 text-red-500">{error}</div>;

  return (
    <>

<Sidebar/>         
      <div className="containe mx-auto mt-0 sm:px-10 lg:px-16 lg:px-16 lg:pl-20 lg:pr-20" style={{ background:'white', width:"100%"}}>
      
      <br/>
      <br/>
      <br/>
      <button onClick={handleClick} className="flex items-center mb-5 pl-5 text-pink-600 font-bold">
        <UndoSharpIcon className="mr-2 text-2xl" />
        Revenir
      </button>

          {/* Section Cover */}
          <div className="relative w-full h-64 bg-gray-200 overflow-hidden mb-6">

 

          <div className="relative w-full h-64"> {/* Ajustez la hauteur selon vos besoins */}
  {adData.statut && adData.statut.toLowerCase() === "active" ? (
    <a href={adData.publicite_url} target="_blank" rel="noopener noreferrer">
      <img
        src={adData.image_url}
        alt="Cover"
        className="absolute inset-0 w-full h-full object-cover opacity-70"
      />
    </a>
  ) : (
    <div className="relative w-full h-64 bg-gray-200 overflow-hidden mb-6">
    
    <img
      src="../../../../abstract_color_streak_facebook_cover_1349547491.jpg"
      alt="Cover"
      className="absolute inset-0 w-full h-full object-cover opacity-70"
    />
    <div className="absolute inset-0 bg-black opacity-40"></div>
    <div className="relative z-10 flex flex-col justify-center items-center h-full">
      <h1 className="text-white text-3xl font-bold mb-4">Boutique</h1>
      <button className="bg-pink-500 text-white px-6 py-2 rounded-full font-semibold hover:bg-pink-600 transition-colors">
      services - {username}
      </button>
    </div>
  </div>
  )}
</div>
</div>

        
      <div className="flex flex-col lg:flex-row lg:space-x-4">
        

        
        {/* Bloc pour les annonces de l'utilisateur */}
        <div className="bg-white p-4 mb-4 lg:mb-0 lg:w-4/4">
          <h2 className="text-xl font-bold flex items-center mb-5" style={{color:"rgb(18, 140, 126)", fontSize:18}}>
            <PermIdentityIcon className="mr-2 text-2xl" style={{fontSize:30}}/>
            services - {username}
            {/*
            <button
                onClick={openModal}
                className="ml-4 bg-blue-600 text-white px-3 py-1 rounded-lg shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                style={{fontSize:16, background:'#e91e64', color:'white'}}
              >
                Contacter
              </button>
             */}
          </h2>
          {/* Affichage des annonces ici */}
          <hr/>
          <br/>
          <div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {annonces.length === 0 ? (
            <p className="text-center col-span-full">Aucune annonce trouvée pour cet utilisateur.</p>
          ) : (
            annonces.map(annonce => (
              
              <div
                      key={annonce.id}
                      className="card shadow-lg rounded-lg flex flex-col gap-2 p-0 bg-white "
                      style={{
                        //boxShadow: "0 2px 8px #00000026",
                        boxShadow: "0px 1px 10px -2px #828282",
                        borderRadius: ".5rem",
                      }}
                    >
                      <Link to={`/details/${annonce.slug}`}>
                      <div className="w-full h-64 sm:h-80 md:h-96 lg:h-[200px] overflow-hidden flex justify-center items-center bg-gray-100 relative">
                          <img
                            src={
                              annonce.featured_image ||
                              "https://via.placeholder.com/150"
                            }
                            alt={annonce.title}
                            className="w-full h-full object-cover rounded-lg "
                          />

                          {/* Étiquette de la catégorie */}
                <div
                  className="absolute top-2 right-2 bg-pink-500 text-white py-1 px-2 rounded-lg text-sm font-semibold"
                  style={{ zIndex: 10 }}
                >
                  {annonce.categories}
                </div>
                        </div>
                      </Link>
                      <h2
                        className="text-lg font-medium pl-5 pr-5 pb-5"
                        style={{ fontWeight: 700, fontSize: "17px" }}
                      >
                        <Link to={`/details/${annonce.slug}`}>
                          {/*truncateText(annonce.title, 10)*/}
                          {annonce.title}
                        </Link>

                        <div
                          className="flex items-center"
                          style={{
                            fontWeight: 400,
                            color: "#6c6c6c",
                            fontSize: 15,
                          }}
                        >
                          <FaCoins className="mr-2" />
                          {annonce.acf_fields.cout
                            ? `${parseInt(
                                annonce.acf_fields.cout
                              ).toLocaleString()} F CFA`
                            : "Non spécifié"}
                          <button
                            className={`wishlist-button ${
                              wishlist.some((item) => item.id === annonce.id)
                                ? "active"
                                : ""
                            }`}
                            onClick={(e) => {
                              e.stopPropagation(); // Empêche le clic de passer au Link
                              toggleWishlist(annonce);
                            }}
                            style={{
                              margin: "0 1rem",
                              background: "none",
                              border: "none",
                            }}
                          >
                            <FaHeart
                              style={{
                                color: wishlist.some(
                                  (item) => item.id === annonce.id
                                )
                                  ? "#E91E63"
                                  : "grey",
                                fontSize: "1.5rem",
                              }}
                              className="step-1"
                            />
                          </button>

                          <TtyOutlinedIcon
                                className="mr-2"
                                style={{
                                  color: "#128c7e",
                                  fontSize: 25,
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  //console.log(annonce.id);
                                  //console.log(annonce.author_id);
                                  trackClick(annonce.id, annonce.author_id, 'call'); // Enregistrement du clic
                                  window.open(
                                    `tel:${annonce.acf_fields.telephone}`,
                                    "_blank"
                                  );
                                }}
                              />
                              <SmsIcon
                                className="ml-2"
                                style={{
                                  color: "",
                                  fontSize: 25,
                                  cursor: "pointer",
                                }}
                                onClick={(e) => {
                                  trackClick(annonce.id, annonce.author_id, 'sms'); // Enregistrement du clic
                                  e.preventDefault(); // Empêche le comportement par défaut
                                  Swal.fire({
                                    title: "Attention",
                                    text: "N’effectuez aucun paiement avant d’avoir l’assurance que c’est le bon prestataire !",
                                    icon: "warning",
                                    showCancelButton: true,
                                    confirmButtonText: "Envoyer SMS",
                                    cancelButtonText: "Annuler",
                                    reverseButtons: true,  // Inverse l'ordre des boutons
                                    customClass: {
                                      cancelButton: "swal2-cancel-button",
                                    },
                                  }).then((result) => {
                                    if (result.isConfirmed) {
                                      // Si l'utilisateur confirme, le SMS est envoyé
                                      const message = `Bonjour Madame/Monsieur ${
                                        annonce.author_name
                                      }, j'ai trouvé votre service sur WEDDAPP.CI, lien : ${`${process.env.REACT_APP_SITE_DOMAINE}details/${annonce.slug}`}, je suis intéressé.`;

                                      window.open(
                                        `sms:${
                                          annonce.acf_fields.telephone
                                        }?body=${encodeURIComponent(message)}`,
                                        "_blank"
                                      );
                                    }
                                  });
                                }}
                              />
                        </div>

                        <div
                          className="flex items-center"
                          style={{
                            fontWeight: 800,
                            color: "#E91E63",
                            fontSize: 15,
                          }}
                        >
                          
                          <Link>
                          <PersonOutlineOutlinedIcon className="mr-1" /> par {annonce.author_name || "Inconnu"}</Link>
                          
                          {/*  <span className="ml-4 items-center flex" style={{fontSize:10, color:'green'}}><FaCheckDouble/> identité vérifiée</span> */}
                        </div>
                        <div
                          className="flex items-center"
                          style={{
                            fontWeight: 800,
                            color: "#128c7e",
                            fontSize: 15,
                          }}
                        >
                          <FaCity className="mr-2" />
                          service à {annonce.communes || "Inconnu"}
                          {/*  <span className="ml-4 items-center flex" style={{fontSize:10, color:'green'}}><FaCheckDouble/> identité vérifiée</span> */}
                        </div>

                        <div
                              className="flex justify-center items-center"
                              style={{
                                fontWeight: 800,
                                color: "white",
                                fontSize: 15,
                                background: "#075E54",
                                padding: "4px",
                                borderRadius: 4,
                                marginTop: 5,
                                cursor: "pointer",
                              }}
                              onClick={(e) => {
                                trackClick(annonce.id, annonce.author_id, 'whatsapp'); // Enregistrement du clic
                                e.preventDefault(); // Empêche la redirection automatique
                                Swal.fire({
                                  title: "Attention",
                                  text: "N’effectuez aucun paiement avant d’avoir l’assurance que c’est le bon prestataire !",
                                  icon: "warning",
                                  showCancelButton: true,
                                  reverseButtons: true,  // Inverse l'ordre des boutons
                                  confirmButtonText: "Continuer",
                                  cancelButtonText: "Annuler",
                                  customClass: {
                                    cancelButton: "swal2-cancel-button",
                                  },
                                }).then((result) => {
                                  if (result.isConfirmed) {
                                    // Redirige vers WhatsApp si l'utilisateur confirme
                                    window.open(
                                      createWhatsAppLink(
                                        annonce.acf_fields.telephone,
                                        `Bonjour Madame/Monsieur ${
                                          annonce.author_name
                                        }, j'ai trouvé votre service sur WEDDAPP.CI, lien : ${`${process.env.REACT_APP_SITE_DOMAINE}details/${annonce.slug}`}, je suis intéressé.`
                                      ),
                                      "_blank",
                                      "noopener,noreferrer"
                                    );
                                  }
                                });
                              }}
                            >
                              {annonce.acf_fields.cout ? (
                                <div>
                                  <FontAwesomeIcon icon={faWhatsapp} /> écrire
                                  sur whatsapp
                                </div>
                              ) : (
                                "Non spécifié"
                              )}
                            </div>
                      </h2>
                    </div>

            ))
          )}

          {/* Modale de contact */}
        {isModalOpen && (
          <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
            <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md" style={{margin:10}}>
              <h2 className="text-2xl font-semibold mb-4 items-center flex" style={{fontSize:18}}>
              <FaWhatsapp className="mr-2 text-xl" /> Contacter {username}
              </h2>
              <form onSubmit={handleSubmit}>
              <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2 flex items-center" htmlFor="nom" style={{fontSize:16}}>
                <PersonIcon className="mr-2" />
                Votre Nom
              </label>
              <input
                type="text"
                id="nom"
                name="nom"
                className="w-full px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2 flex items-center" htmlFor="prenom" style={{fontSize:17}}>
                Votre Prénom
              </label>
              <input
                type="text"
                id="prenom"
                name="prenom"
                className="w-full px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2 flex items-center" htmlFor="contact" style={{fontSize:17}}>
                <SmartphoneIcon className="mr-2" />
                Votre Contact
              </label>
              <input
                type="number"
                id="contact"
                name="contact"
                className="w-full px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2 flex items-center" htmlFor="message" style={{fontSize:17}}>
                <MailOutlineIcon className="mr-2" />
                Votre Message
              </label>
              <textarea
                id="message"
                name="message"
                rows="4"
                className="w-full px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>

                <div className="flex justify-end">
                  <button
                    type="button"
                    onClick={closeModal}
                    className="bg-gray-500 text-white px-4 py-2 rounded-lg shadow-sm hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50 mr-2"
                  >
                    Annuler
                  </button>
                  


                  <button
              type="submit"
              className="bg-green-600 text-white px-4 py-2 rounded-lg shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 flex items-center font-bold"
            >
              <FaWhatsapp className="mr-2 text-xl" />
              Envoyer
            </button>


                </div>
              </form>
            </div>
          </div>
        )}
        </div>        
          </div>        
        </div>

        {/* Bloc pour contacter l'utilisateur */}
        
        
      </div>
      <br/>
        <br/>
        <br/>
        <br/>
    </div>
    
    <CustomBottomNavigation />
    </>
  );
};

export default AnnoncesByUser;
