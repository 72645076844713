import React, { useState, useEffect } from "react";
import "./Home.css";
import CustomBottomNavigation from "../../common/BottomNavigationAction";
import Modal from "./Modal";
import {
  FaCalculator,
  FaCity,
  FaCoins,
  FaUserFriends,
  FaCalendarDay,
} from "react-icons/fa";
import { Bars } from "react-loader-spinner";
import Swal from "sweetalert2";

import axios from "axios";
import Alert from "../../Alert";

const Simulateur = () => {
  const [budget, setBudget] = useState("");
  const [guests, setGuests] = useState("");
  const [commune, setCommune] = useState("");
  const [day, setDay] = useState("");
  const [daysAvailable, setDaysAvailable] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [visitCount, setVisitCount] = useState("");
  const [initialLoading, setInitialLoading] = useState(false);
  const [totalExpenses, setTotalExpenses] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(false);


  
  const [formState, setFormState] = useState({
    budget: '0 F CFA',
    guests: 0,
    commune: '',
    mairieCost: '', // Exemple de coût de mairie
  });



  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [results, setResults] = useState({ indispensables: [], facultatives: [] });

  useEffect(() => {
    // Remplacez l'URL par celle de votre API si nécessaire
    const url = `${process.env.REACT_APP_API_DOMAIN}/wp-json/v1/afficher_notification_alerte`;
    axios
      .get(url)
      .then((response) => {
        // Assurez-vous que la réponse contient le champ 'afficher_notification_alerte'
        if (response.data && response.data.afficher_notification_alerte) {
          setShowAlert(true);
        } else {
          setShowAlert(false);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la récupération des notifications :", error);
        setShowAlert(false);
      });
  }, []); // Le tableau vide signifie que l'effet s'exécute une seule fois après le premier rendu


  const mairieTarifs = {
    Koumassi: {
      Lundi: 35000,
      Mardi: 35000,
      Mercredi: 35000,
      Jeudi: 35000,
      Vendredi: 35000,
      Samedi: 50000,
    },
    Plateau: {
      Lundi: 50000,
      Mardi: 50000,
      Mercredi: 50000,
      Jeudi: 50000,
      Vendredi: 50000,
      Samedi: 50000,
    },
    Yopougon: {
      Jeudi: 50000,
      Samedi: 50000,
    },
    Cocody: {
      Mercredi: 100000,
      Jeudi: 100000,
      Vendredi: 100000,
      Samedi: 100000,
    },
    "Port-Bouët": {
      Lundi: 50000,
      Mardi: 50000,
      Mercredi: 50000,
      Jeudi: 25000,
      Vendredi: 50000,
      Samedi: 50000,
    },
    Marcory: {
      Jeudi: 50000,
      Vendredi: 50000,
      Samedi: 60000,
    },
    Attecoubé: {
      Lundi: 50000,
      Mardi: 50000,
      Mercredi: 50000,
      Jeudi: 40000,
      Vendredi: 50000,
      Samedi: 50000,
    },
    Bingerville: {
      Jeudi: 60000,
      Vendredi: 60000,
      Samedi: 65000,
    },
    Adjamé: {
      Jeudi: 30000,
      Samedi: 40000,
    },
    "Hotel du District": {
      Lundi: 100000,
      Mardi: 100000,
      Mercredi: 100000,
      Jeudi: 100000,
      Vendredi: 100000,
      Samedi: 100000,
    },
  };


  const formatNumber = (value) => {
    if (!value) return "";
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  };

  const parseNumber = (value) => {
    return value.replace(/\s+/g, "");
  };

  const handleBudgetChange = (e) => {
    const inputValue = e.target.value;
    const numericValue = parseNumber(inputValue);
    const formattedValue = formatNumber(numericValue);
    setBudget(formattedValue);
  };

  const handleKeyDown = (event) => {
    // Permet uniquement les chiffres, la touche Backspace, la touche Tab, et la touche Enter
    const allowedKeys = [
      'Backspace',
      'Tab',
      'Enter',
      'ArrowLeft',
      'ArrowRight',
      'Delete',
      'ArrowUp',
      'ArrowDown',
      'Home',
      'End',
      'Numpad0', 'Numpad1', 'Numpad2', 'Numpad3', 'Numpad4', 'Numpad5', 'Numpad6', 'Numpad7', 'Numpad8', 'Numpad9',
    ];

    if (event.key.length === 1 && !/[\d]/.test(event.key) && !allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  };

  const handleCommuneChange = (e) => {
    const selectedCommune = e.target.value;
    setCommune(selectedCommune);
    setDaysAvailable(Object.keys(mairieTarifs[selectedCommune] || {}));
    setDay("");
  };


  
  const handleSubmit = (event) => {
    event.preventDefault();
    const budgetAmount = parseFloat(budget);
    const numberOfGuests = parseInt(guests);
    const selectedDay = day;
    const selectedCommune = commune;
    
    if (!budgetAmount || !numberOfGuests || !selectedDay || !selectedCommune) {
      alert("Veuillez remplir tous les champs correctement.");
      return;
    }

    const mairieCost = mairieTarifs[selectedCommune][selectedDay];
    if (!mairieCost) {
      alert("Coût de la mairie non disponible pour ce jour.");
      return;
    }

    const totalIndispensable = {
      "Frais de Mairie": mairieCost,
      "Lieu de Réception": 150000,
      "Restauration (nourriture)": numberOfGuests * 2000,
      "Boisson": numberOfGuests * 500,
      "Gâteau": 30000,
      "Robe de Mariée": 70000,
      "Costume": 50000,
      "Photographie - Camera": 40000,
      "Faire-part et Invitations": 20000,
      "Coiffure de la Mariée": 15000,
      "Maquillage de la Mariée": 15000,
      "Location de Chaises": 20000,
      "Bâche": 20000,
      "Tréteaux": 15000,
      "Ustensiles (couverts, etc.)": 15000,
      "Vaisselle (assiettes, verres, etc.)": 15000,
      "Nombre d'Invités (en frais divers)": 20000
    };

    const totalFacultatif = {
      "Musique et Animation": 40000,
      "Vidéaste": 50000,
      "Voiture de Transport pour les mariés": 30000,
      "Véhicule de Transport du Cortège": 25000,      
      "Coiffure et Maquillage du Marié": 20000,
      "Hôtel ou Maison pour le Samedi": 80000,
      "Lune de Miel": 100000,
      "Lieu de la pause photo": 15000,
      "Véhicule à disposition pour les photographes": 10000
    };

    let totalIndispensableCost = Object.values(totalIndispensable).reduce((acc, curr) => acc + curr, 0);
    let totalFacultatifCost = Object.values(totalFacultatif).reduce((acc, curr) => acc + curr, 0);

    const totalBudget = budgetAmount - mairieCost;
    
    let remainingBudget = totalBudget - totalIndispensableCost;

    setResults({
      indispensables: Object.entries(totalIndispensable).map(([key, value]) => ({ name: key, cost: value })),
      facultatives: remainingBudget >= 0
        ? Object.entries(totalFacultatif).map(([key, value]) => ({ name: key, cost: value }))
        : [],
      mairieCost
    });
    
    setModalIsOpen(true);
  };

 
  const closeModal = () => {
    //setIsModalOpen(false);
    setModalIsOpen(false)
    resetForm(); // Réinitialiser le formulaire lorsque le modal est fermé
  };

  const resetForm = () => {
    // Réinitialiser les états du formulaire
    setBudget('');
    setGuests('');
    setCommune('');
    setDay('');
    setDaysAvailable([]);
    setResult(null);
    setTotalExpenses(0);
    // Si vous avez un état spécifique pour les coûts de la mairie, réinitialisez-le aussi
    setFormState({
      budget: '0 F CFA',
      guests: 0,
      commune: '',
      mairieCost: '', // Réinitialiser ce champ si nécessaire
    });
  
  };
  
  
  return (
    <>
    {console.log(showAlert)}
      {showAlert && <Alert />}

      {initialLoading ? (
        <div className="loading-overlay">
          <Bars
            height="100"
            width="100"
            color="#e91e63"
            ariaLabel="bars-loading"
          />
        </div>
      ) : (
        <>
          <div className="container" style={{ marginTop: 24 }}>
            <div className="form-block">
              <center>
                <img
                  src="../../../../WEEDDAPP_LOGO.png"
                  alt="Logo"
                  style={{ width: "100px" }}
                />
              </center>
              <h1
                style={{ fontSize: 22, color: "black", fontWeight: "bolder" }}
              >
                Simulateur de Budget de Mariage{" "}
                <span style={{ fontSize: 14, color: "#e91e64" }}>v1.01</span>
              </h1>
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label
                    htmlFor="budget"
                    className="flex items-center space-x-2"
                  >
                    <FaCoins className="text-gray-600" />
                    <span style={{ fontWeight: "600", color: "" }}>
                      Budget Total (en F CFA):
                    </span>
                  </label>
                  <input
                    type="text"
                    id="budget"
                    name="budget"
                    value={budget}
                    onChange={handleBudgetChange}
                    onKeyDown={handleKeyDown}
                    inputMode="numeric"
                    required
                    style={{
                      width: "100%",
                      height: 40,
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      padding: "8px",
                    }}
                    className="w-full h-10 border border-gray-300 rounded-lg p-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"

                  />
                </div>

                <div className="form-group">
                  <label
                    htmlFor="guests"
                    className="flex items-center space-x-2"
                  >
                    <FaUserFriends className="text-gray-600" />
                    <span style={{ fontWeight: "600", color: "" }}>
                      Nombre d'Invités:
                    </span>
                  </label>
                  <input
                    type="number"
                    id="guests"
                    name="guests"
                    value={guests}
                    onChange={(e) => setGuests(e.target.value)}
                    onKeyDown={handleKeyDown}
                    required
                    className="w-full h-10 border border-gray-300 rounded-lg p-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>

                <div className="form-group">
                  <label
                    htmlFor="commune"
                    className="flex items-center space-x-2"
                  >
                    <FaCity className="text-gray-600" />
                    <span style={{ fontWeight: "600", color: "" }}>
                      A la Mairie de :
                    </span>
                  </label>
                  <select
                    id="commune"
                    name="commune"
                    value={commune}
                    onChange={handleCommuneChange}
                    required
                    className="w-full h-10 border border-gray-300 rounded-lg p-2 text-gray-700 bg-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                  >
                    <option value="">Sélectionnez une commune</option>
                    {Object.keys(mairieTarifs).map((commune) => (
                      <option key={commune} value={commune}>
                        {commune}
                      </option>
                    ))}
                  </select>
                </div>

                {daysAvailable.length > 0 && (
                  <div className="form-group">
                    <label
                      htmlFor="day"
                      className="flex items-center space-x-2 text-gray-700 font-semibold mb-2"
                    >
                      <FaCalendarDay className="text-gray-600" />
                      <span style={{ fontWeight: "600", color: "" }}>
                        Jours possibles de Mariage:
                      </span>
                    </label>
                    <select
                      id="day"
                      name="day"
                      value={day}
                      onChange={(e) => setDay(e.target.value)}
                      required
                      className="w-full h-10 border border-gray-300 rounded-lg p-2 text-gray-700 bg-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                      <option value="">Sélectionnez un jour</option>
                      {daysAvailable.map((day) => (
                        <option key={day} value={day}>
                          {day}
                        </option>
                      ))}
                    </select>
                  </div>
                )}



                <button
                  type="submit"
                  className="flex items-center space-x-4 px-4 py-2 bg-pink-600 text-white font-bold rounded-lg shadow-md hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-pink-500 transition-colors duration-300"
                  disabled={loading}
                  style={{ fontWeight: 'bolder', background: '#e91e63', width:'auto', float:'right' }}
                >
                  {loading ? (
                    <Bars
                      height="30"
                      width="30"
                      color="white"
                      ariaLabel="bars-loading"
                    />
                  ) : (
                    <>
                    <FaCalculator className='mr-1'/> simuler
                    </>
                  )}
                </button>


              </form>
            </div>
          </div>

          <Modal
  isOpen={modalIsOpen}
  onClose={closeModal}
  //onClose={() => setModalIsOpen(false)}
  budget={budget}
  guests={guests}
  commune={commune}
  //indispensables={results.indispensables}
  //facultatives={results.facultatives}
  mairieCost = {results.mairieCost}

/>

          <CustomBottomNavigation />
        </>
      )}
    </>
  );
};

export default Simulateur;
