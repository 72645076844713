import React from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

export const CustomPrevArrow = ({ onClick }) => {
  return (
    <div
      className="absolute top-1/2 left-4 transform -translate-y-1/2 z-10 cursor-pointer"
      onClick={onClick}
    >
      <div className="p-2 bg-black rounded-full">
        <FaArrowLeft size={17} className="text-white" />
      </div>
    </div>
  );
};

export const CustomNextArrow = ({ onClick }) => {
  return (
    <div
      className="absolute top-1/2 right-4 transform -translate-y-1/2 z-10 cursor-pointer"
      onClick={onClick}
    >
      <div className="p-2 bg-black rounded-full">
        <FaArrowRight size={17} className="text-white" />
      </div>
    </div>
  );
};
